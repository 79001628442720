import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import classnames from 'classnames';
import { ReactComponent as Profile } from '../../images/icons/profile.svg';
import { ReactComponent as Wishlist } from '../../images/icons/wishlist24px.svg';
import Avatar from '../Avatar/Avatar';
import { FormattedMessage, useIntl } from 'react-intl';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import constructURL from '../../utils/constructURL';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CONFIG_SHAPE } from '../../state/config/config';
import styles from './HeaderMenuItems.module.scss';
import animations from '../../styleguide/animations.module.scss';
import { ReactComponent as Cart } from '../../images/icons/cart24px.svg';
import { CART } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import useMediaQuery from '../../utils/useMediaQuery';
import { getPagePath, getPopupOnPagePath } from '../../utils/appRoutes';
import {
  ARD_CART_OVERVIEW,
  ARD_POPUP_CART,
  ARD_POPUP_PROFILE,
  ARD_POPUP_PROFILE_WISHLIST,
} from '../../utils/appRoutes.definitions';
import { usePopupOpened } from '../../utils/usePopupOpened';
import { useSwitchableFeatures } from '../../features/SwitchableFeature/SwitchableFeature';
import useWishlist from '../../utils/useWishlist';
import { useAuth0Wrapper } from '../../utils/auth0';

const ProfilePopup = loadable(() => import('../ProfilePopup/ProfilePopup'));
const WishlistPopup = loadable(() => import('../WishlistPopup/WishlistPopup'));
const CartPopup = loadable(() => import('../CartPopup/CartPopup'));

const HeaderMenuItems = ({ classList, config, profile }) => {
  const location = useLocation();
  const intl = useIntl();
  const { push } = useHistory();
  const openCarts = useSelector(({ cart }) => cart.openCarts);
  const [marketplaceVersion] = useSwitchableFeatures([CART]);
  const { wishlistProducts } = useWishlist();
  const { isAuthenticated, isLoading } = useAuth0Wrapper();
  const [profilePopupOpened, wishlistPopupOpened, cartPopupOpened] = usePopupOpened(
    ARD_POPUP_PROFILE,
    ARD_POPUP_PROFILE_WISHLIST,
    ARD_POPUP_CART
  );

  const productCountInCart = openCarts?.reduce((acc, cart) => {
    return acc + cart.products.reduce((amount, product) => amount + product.quantity, 0);
  }, 0);

  const profilePopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_PROFILE));
  const wishlistPopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_PROFILE_WISHLIST));
  const cartPopupUrl = constructURL(getPopupOnPagePath(ARD_POPUP_CART));

  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery();
  const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);

  useEffect(() => {
    const handler = () => {
      if ((profilePopupOpened || wishlistPopupOpened || cartPopupOpened) && isDesktop) {
        push({ ...location, hash: '' });
      }
    };
    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [cartPopupOpened, isDesktop, location, profilePopupOpened, push, wishlistPopupOpened]);

  const pageUrlNoPopups = constructURL(location.pathname, {
    ignoreExistingHash: true,
  });

  const getPopupOnDesktop = (popupUrl, popupOpened) => {
    if (!isDesktop) return;
    if (popupOpened) return pageUrlNoPopups;
    else return popupUrl;
  };

  return (
    <div
      className={classnames({
        [classList.root]: classList.root,
        [styles.userIcons]: !marketplaceVersion,
        [styles.userIconsAlternative]: marketplaceVersion,
      })}
    >
      <div className={classnames(styles.menu)}>
        <Link
          aria-label={config.cmsSlugs.profile}
          to={profilePopupOpened ? pageUrlNoPopups : profilePopupUrl}
          className={classnames(styles.isOpen, {
            [styles.loggedIn]: isAuthenticated,
            [styles.hiddenArrow]: !profilePopupOpened,
            [styles.avatarLoading]: isLoading,
          })}
        >
          {!!isLoading && <Avatar />}
          {!isLoading && !isAuthenticated && (
            <>
              <Profile className={animations.fadeIn} />
              <p>
                <FormattedMessage id="common.logIn" defaultMessage="Log in" />
              </p>
            </>
          )}
          {!!isAuthenticated && (
            <Avatar
              src={profile?.picture}
              classList={{ animation: animations.fadeIn }}
              salutation={profile?.salutation}
            />
          )}
        </Link>
        <Link
          aria-label={intl.formatMessage({ id: 'profile.wishlist' })}
          to={wishlistPopupOpened ? pageUrlNoPopups : wishlistPopupUrl}
          className={classnames(
            styles.isOpen,
            {
              [styles.hiddenArrow]: !wishlistPopupOpened,
              [styles.hasItems]: wishlistProducts?.length,
            },
            styles.wishlistIcon
          )}
        >
          {!!wishlistProducts?.length && <span className={styles.counterBadge}>{wishlistProducts.length}</span>}
          <Wishlist viewBox="0 0 24 24" />
          <p>
            <FormattedMessage id="common.wishlist" defaultMessage="Wishlist" />
          </p>
        </Link>
        <SwitchableFeature feature={CART}>
          <Link
            to={getPopupOnDesktop(cartPopupUrl, cartPopupOpened) || getPagePath(ARD_CART_OVERVIEW)}
            aria-label="Cart"
            className={classnames(styles.badgeCounterCountainer, styles.isOpen, {
              [styles.hiddenArrow]: !cartPopupOpened,
              [styles.hasItems]: true,
            })}
          >
            {!!productCountInCart && <span className={styles.counterBadge}>{productCountInCart}</span>}
            <Cart viewBox="0 0 24 24" className={styles.cartIcon} />
            <p>
              <FormattedMessage id="common.cart" defaultMessage="Cart" />
            </p>
          </Link>
        </SwitchableFeature>
      </div>
      <ProfilePopup shouldBeOpen={profilePopupOpened} />
      <WishlistPopup shouldBeOpen={wishlistPopupOpened} />
      <SwitchableFeature feature={CART}>
        <CartPopup shouldBeOpen={cartPopupOpened} />
      </SwitchableFeature>
    </div>
  );
};

export const mapStateToProps = ({ config, profile }) => ({
  config,
  profile,
});

HeaderMenuItems.propTypes = {
  classList: PropTypes.objectOf(PropTypes.string),
  config: CONFIG_SHAPE,
  searchTerm: PropTypes.string,
  profile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(HeaderMenuItems);
